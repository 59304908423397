<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Add</vs-button>
      </div>
    </div>
    <DiscountList
      :data="data"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getList"
      @editDetail="editDetail"
      @deleteItem = "deleteItem"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DiscountList from "../../components/webstore-discounts/list";

export default {
  name:"ListDiscount",
  components: {
    DiscountList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      popUpDelete:false,
      data: [],
      noDataText: 'Loading...'
    };
  },
  methods: {
    ...mapActions("discount", [
      "getDiscountList",
      "deleteDiscountById"
    ]),
    async getList(dataTableParams) {
      let self = this;
      this.getDiscountList(dataTableParams).then( async (res) => {
        self.data = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = 'No Discount Available'
      });
    },
    createHandler(){
      this.$router.push({ name: 'AddDiscount'})
    },
    editDetail(id) {
      this.$router.push({
        name: "EditDiscount",
        params: { discountId: id }
      });
    },
    async deleteItem(data){
      let self=this;
      await this.deleteDiscountById(data.id)
        .then(res => {
          self.popUpDelete = false;
          self.getList(data.dataTableParams);
          this.$vs.notify({
            title: "Success",
            text: "Discount Deleted successfully.",
            color: "success"
          });
        })
        .catch(err => {
          self.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger"
          });
        });
    }
  }
};
</script>

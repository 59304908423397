<template>
  <vx-card class="discountList">
    <div class="vx-col lg:w-1/2" align="left">
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="data"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex">
          <div>
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th>Discount Type</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">
              {{
              data[indextr].name
              }}
            </vs-td>
            <vs-td :data="data[indextr].discountType">
              {{
              data[indextr].discountType
              }}
            </vs-td>

            <vs-td :data="data[indextr]._id">
              <div class="flex items-center justify-start space-x-2">
                <vx-tooltip text="Update" position="left" >
                  <vs-button
                    type="border"
                    size="small"
                    @click="editDetail(data[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-edit"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="left">
                <vs-button
                  type="border"
                  size="small"
                  color="danger"
                  @click="openDeletePopup(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-trash"
                ></vs-button>
              </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>


      <vs-popup :active.sync="popUpDelete" title="Delete Discount">
        <div class="p-6">
          <p class="text-center text-black text-lg font-medium mb-6">Are you sure you want to delete this discount?</p>
          <div class="flex items-center justify-between space-x-4">
                <vs-button
                  size="lg"
                  color="primary"
                  class="flex-1"
                  @click="deleteDetailHandler(popupData.id)"
                >Yes</vs-button>
                <vs-button class="flex-1" size="lg" color="danger" @click="popUpDelete = false">Cancel</vs-button>
          </div>
        </div>
      </vs-popup>
            
      <div class="flex items-center justify-between space-x-4 mt-3">
      <p class="text-sm font-medium">
        {{
        dataTableParams.page * dataTableParams.limit -
        (dataTableParams.limit - 1)
        }}
        -
        {{
        totalDocs - dataTableParams.page * dataTableParams.limit > 0
        ? dataTableParams.page * dataTableParams.limit
        : totalDocs
        }}
        of {{ totalDocs }}
      </p>
      <div v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
    </div>
  </vx-card>
</template>

<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";

  export default {
    name: "DiscountList",
    components: {
      "v-select": vSelect
    },
    props: ["data", "totalDocs", "page", "noDataText","popUpDeleteParent"],
    data() {
      return {
        // popUpDelete: this.popUpDeleteParent,
        popUpDelete: false,
        customerCSVurl: false,
        isMounted: false,
        currentPage: 1,
        popupData:{
          id:'',
          productData:[],
          subCategoryData: []
        },
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "desc",
          page: 1,
          limit: 10
        },
        limitOptions: [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 }
        ],
        serverResponded: false,
        awaitingSearch: null
      };
    },
    methods: {
      ...mapActions("ecommerce",["algoliaSync"]),
      async fetchList() {
        this.$vs.loading();
        await this.$emit("fetchList", this.dataTableParams);
        this.serverResponded = true;
        this.$vs.loading.close();
      },
      editDetail(id) {
        this.$emit("editDetail", id);
      },
      openDeletePopup(id){
        this.popupData.id = id;
        this.popUpDelete=true;
      },
      handleSearch(searching) {
        if (this.awaitingSearch) {
          clearTimeout(this.awaitingSearch);
          this.awaitingSearch = null;
        }
        this.awaitingSearch = setTimeout(() => {
          this.serverResponded = false;
          this.dataTableParams.search = searching;
          this.dataTableParams.page = 1;
          this.$refs.table.currentx = 1;
          this.fetchList();
        }, 500);
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.fetchList();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      },
      async deleteDetailHandler(id) {
        await this.$emit("deleteItem", {id,dataTableParams:this.dataTableParams});
        this.popUpDelete = false;
        self.algoliaSync()
      },
      exportCsvHandler() {
        let _instance = this;
        let csvLinkInstance = _instance.$refs.downloadCustomerCSVRef;

        //csvLinkInstance.click();
        this.exportCsv()
          .then(async res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "freshclinic-patients.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.fetchList();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.fetchList();
        }
      }
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      }
    },
    created() {
      this.noDataText = "Loading...";
      this.fetchList();
    }
  };
</script>


<style lang="scss">
  @media screen and (max-width: 768px) {
    .discountList {
      .vx-card .vx-card__collapsible-content .vx-card__body {
        padding: 0;
      }
    }
  }
</style>